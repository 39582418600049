document.addEventListener("turbolinks:load", () => {
  var infoTableButton = document.getElementById("toggle-info-table");
  var versionsTableButton = document.getElementById("toggle-versions-table");

  var infoTable = document.getElementById("series-info");
  var versionsTable = document.getElementById("versions-info");

  if(infoTableButton) {
    infoTableButton.addEventListener("click", e => {
      e.preventDefault();

      if (infoTable.style.display == "none") {
        versionsTable.style.display = "none";
        infoTable.style.display = "block";

        versionsTableButton.classList.replace("btn-primary", "btn-secondary");
        infoTableButton.classList.replace("btn-secondary", "btn-primary");
      }
    });
  }

  if(versionsTableButton) {
    versionsTableButton.addEventListener("click", e => {
      e.preventDefault();

      if (versionsTable.style.display == 'none') {
        infoTable.style.display = "none";
        versionsTable.style.display = "block";

        infoTableButton.classList.replace("btn-primary", "btn-secondary");
        versionsTableButton.classList.replace("btn-secondary", "btn-primary");
      }
    });
  }
});
