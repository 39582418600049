document.addEventListener("turbolinks:load", () => {
  var toggleButton = document.getElementById("toggle-filters");
  var filtersDiv = document.getElementById("filters");

  if(toggleButton) {
    toggleButton.addEventListener("click", e => {
      e.preventDefault();

      if (filtersDiv.style.display == "block") {
        filtersDiv.style.display = "none";
      } else {
        filtersDiv.style.display = "block";
      }
    });
  }
});
