document.addEventListener("turbolinks:load", () => {
  document.querySelectorAll("a[id^='toggle-additional-info-']").forEach( button => {
    var episodeId = button.id.replace("toggle-additional-info-", "");
    var toggleIconClassList = button.querySelector('i').classList;
    var progressBarToggle = document.getElementById("progress-" + button.id);
    var additionalInfo = document.getElementById("additional-info-" + episodeId);

    toggleInfo(button, toggleIconClassList, additionalInfo);
    toggleInfo(progressBarToggle, toggleIconClassList, additionalInfo);
  });
});

function toggleInfo(button, icon, additionalInfo) {
  button.addEventListener("click", e => {
    e.preventDefault();

    if (additionalInfo.style.display == "table-row") {
      additionalInfo.style.display = "none";
      icon.replace("fe-arrow-up", "fe-arrow-down");
    } else {
      additionalInfo.style.display = "table-row";
      icon.replace("fe-arrow-down", "fe-arrow-up");
    }
  });
}
